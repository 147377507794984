'use client';
import * as React from 'react';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from '@/providers/theme-provider';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from '@/components/ui/dropdown-menu';
export function ThemeToggle() {
    var setTheme = useTheme().setTheme;
    return (<DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon">
                    <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"/>
                    <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"/>
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={function () { return setTheme('light'); }}>Light</DropdownMenuItem>
                <DropdownMenuItem onClick={function () { return setTheme('dark'); }}>Dark</DropdownMenuItem>
                <DropdownMenuItem onClick={function () { return setTheme('system'); }}>System</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>);
}
