import React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { SideSection } from '@/components/side-section';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Icon } from '@iconify/react';
export var Configuration = function () {
    return (<Sheet>
            <SheetTrigger asChild>
                <Button variant="ghost" className="gap-2">
                    <Icon icon="heroicons-outline:cog"/>
                    Configuration
                </Button>
            </SheetTrigger>
            <SheetContent side="left">
                <SheetHeader>
                    <SheetTitle>Configuration</SheetTitle>
                </SheetHeader>
                <ScrollArea>
                    <SideSection />
                </ScrollArea>
            </SheetContent>
        </Sheet>);
};
