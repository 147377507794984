'use client';
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useId } from 'react';
import { FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
var TextAreaFieldProps = React.memo(function (_a) {
    var id = _a.id, control = _a.control, label = _a.label, name = _a.name, startIcon = _a.startIcon, endIcon = _a.endIcon, props = __rest(_a, ["id", "control", "label", "name", "startIcon", "endIcon"]);
    var localId = useId();
    return (<FormField control={control} name={name} render={function (_a) {
            var field = _a.field;
            return (<FormItem>
                        {label && <FormLabel htmlFor={id || localId}>{label}</FormLabel>}
                        <div className="flex flex-row gap-2">
                            {startIcon && startIcon}
                            <Textarea {...props} {...field} onChange={function (event) {
                    field.onChange(event);
                    props.onChange && props.onChange(event);
                }} id={id || localId}/>
                            {endIcon && endIcon}
                        </div>
                        <FormMessage />
                    </FormItem>);
        }}/>);
});
export default TextAreaFieldProps;
