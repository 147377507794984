'use client';
import React from 'react';
export var SessionStorageContext = React.createContext({
    title: '',
    titleDraft: '',
    description: '',
    descriptionDraft: '',
    edgeConfig: [],
    edgeConfigDraft: [],
    nodeConfig: [],
    nodeConfigDraft: [],
    workflowConfig: undefined,
    workflowConfigDraft: undefined,
    setEdgeConfig: function (_) { },
    setEdgeConfigDraft: function (_) { },
    setNodeConfig: function (_) { },
    setNodeConfigDraft: function (_) { },
    setWorkflowConfig: function () { },
    setWorkflowConfigDraft: function (_) { },
    setDescription: function (_) { },
    setDescriptionDraft: function (_) { },
    setTitle: function (_) { },
    setTitleDraft: function (_) { },
    empty: function () { },
});
