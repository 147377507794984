import React, { useState } from 'react';
import { useSecurity } from '@/hooks/useSecurity';
import { StringHelper } from '@/helpers/string.helper';
import { Button } from '@/components/ui/button';
import { Confirmation } from '@/components/confirmation';
import { formatRelative } from 'date-fns';
import TextareaField from '@/components/textarea-field';
import { useForm } from 'react-hook-form';
import { Form } from '@/components/ui/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteComment, putComment } from '@/api/comment';
import { toast } from 'sonner';
import { Icon } from '@iconify/react';
export var Comment = function (_a) {
    var comment = _a.comment;
    var currentUser = useSecurity().currentUser;
    var _b = useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var queryClient = useQueryClient();
    var mutate = useMutation({
        mutationFn: deleteComment,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['comments'] }).then();
            toast.success('Comment deleted successfully');
        },
        onError: function (error) {
            toast.error(error.message);
        },
    }).mutate;
    var onDelete = function () {
        mutate(comment.id);
    };
    var isSameUser = React.useMemo(function () { return (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id) === comment.user.id; }, [comment.user.id, currentUser === null || currentUser === void 0 ? void 0 : currentUser.id]);
    return (<div className="flex flex-col p-3 min-h-[10rem] space-y-3 relative">
            <div className="flex flex-col">
                <Username user={comment.user} isSameUser={isSameUser}/>
                <span className="text-Grayish-Blue">{formatRelative(comment.updatedAt, new Date())}</span>
            </div>
            <Content setIsEditing={setIsEditing} comment={comment} isEditing={isEditing}/>
            {!isEditing && (<div className="flex flex-row">
                    <CommentButtons isSameUser={isSameUser} onDelete={onDelete} setIsEditing={setIsEditing}/>
                </div>)}
        </div>);
};
var Username = React.memo(function (_a) {
    var user = _a.user, isSameUser = _a.isSameUser;
    var fullName = StringHelper.getFullName(user);
    return (<React.Fragment>
            {(isSameUser && (<div>
                    <span className="text-Dark-blue font-bold">{fullName}</span>
                    <span className="bg-blue px-1.5 rounded-sm mx-3">you</span>
                </div>)) || <span className="text-Dark-blue font-bold">{fullName}</span>}
        </React.Fragment>);
});
var CommentButtons = React.memo(function (_a) {
    var isSameUser = _a.isSameUser, setIsEditing = _a.setIsEditing, onDelete = _a.onDelete;
    if (isSameUser) {
        return (<React.Fragment>
                <Confirmation confirmAction={onDelete} title="Delete comment" description="Are you sure you want to delete this comment?">
                    <Button variant="ghost" className="text-red-500 gap-2">
                        <Icon icon="ion:trash-outline"/>
                        Delete
                    </Button>
                </Confirmation>
                <Button onClick={function () { return setIsEditing(true); }} variant="ghost" className="text-blue-500 gap-2">
                    <Icon icon="lucide:edit"/>
                    Edit
                </Button>
            </React.Fragment>);
    }
    return undefined;
});
var Content = React.memo(function (_a) {
    var isEditing = _a.isEditing, comment = _a.comment, setIsEditing = _a.setIsEditing;
    return (<React.Fragment>
                {(!isEditing && <p className="text-Grayish-Blue my-3 break-words">{comment.message}</p>) || (<ContentEdit comment={comment} setIsEditing={setIsEditing}/>)}
            </React.Fragment>);
});
var schema = object({
    message: string().required('Comment is required').max(255),
});
var ContentEdit = React.memo(function (_a) {
    var comment = _a.comment, setIsEditing = _a.setIsEditing;
    var queryClient = useQueryClient();
    var _b = useMutation({
        mutationFn: putComment,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['comments'] }).then(function () { return form.reset({ message: '' }); });
            setIsEditing(false);
        },
        onError: function (error) {
            toast.error(error.message);
        },
    }), mutate = _b.mutate, isPending = _b.isPending;
    var form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            message: comment.message,
        },
    });
    var onCancel = React.useCallback(function () {
        form.reset({
            message: comment.message,
        });
        setIsEditing(false);
    }, [comment.message, form, setIsEditing]);
    var onSubmit = React.useCallback(function (data) {
        mutate({ id: comment.id, comment: data });
    }, [comment.id, mutate]);
    return (<Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="m-3 flex flex-col">
                    <TextareaField control={form.control} name="message" label="Comment" rows={5} placeholder="Add a comment..."/>
                    <div className="flex justify-end my-4 gap-2">
                        <Button variant="destructive" disabled={isPending} onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isPending}>
                            Update
                        </Button>
                    </div>
                </form>
            </Form>);
});
