import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { postWorkflow, putWorkflow } from '@/api/workflow';
import { Link, useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';
import { Icon } from '@iconify/react';
import { WorkflowConfigHelper } from '@/helpers/workflow-config.helper';
import { useSecurity } from '@/hooks/useSecurity';
import { useSessionStorage } from '@/hooks/useSession';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
export var SaveButton = function (_a) {
    var token = _a.token;
    var navigate = useNavigate();
    var _b = useSessionStorage(), workflowConfigDraft = _b.workflowConfigDraft, titleDraft = _b.titleDraft, title = _b.title, descriptionDraft = _b.descriptionDraft, description = _b.description, nodeConfigDraft = _b.nodeConfigDraft, nodeConfig = _b.nodeConfig, edgeConfigDraft = _b.edgeConfigDraft, edgeConfig = _b.edgeConfig, empty = _b.empty;
    var isAuthenticated = useSecurity().isAuthenticated;
    var _c = useMutation({
        mutationFn: postWorkflow,
        onSuccess: function (value) {
            var data = value.data;
            empty();
            navigate({ to: "/workflows/".concat(data.token) }).then();
        },
        onError: function (error) {
            toast.error('Failed to save workflow');
            console.error({ error: error });
        },
    }), save = _c.mutate, isSaving = _c.isPending;
    var _d = useMutation({
        mutationFn: putWorkflow,
        onSuccess: function () {
            empty();
            toast.success('Workflow updated');
        },
        onError: function (error) {
            toast.error('Failed to save workflow');
            console.error({ error: error });
        },
    }), update = _d.mutate, isUpdating = _d.isPending;
    var handleSave = React.useCallback(function () {
        var configuration = WorkflowConfigHelper.fromNodes(nodeConfigDraft || nodeConfig, edgeConfigDraft || edgeConfig, {
            name: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.name) || '',
            type: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.type) || 'state_machine',
            metadata: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.metadata) || [],
            auditTrail: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.auditTrail) || false,
            markingStore: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.markingStore) || {
                type: '',
                property: '',
            },
            supports: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.supports) || [],
            initialMarking: (workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.initialMarking) || '',
        });
        console.log({ configuration: configuration });
        if (token) {
            update({
                token: token,
                workflow: {
                    title: titleDraft || title,
                    description: descriptionDraft || description,
                    configuration: configuration,
                    nodeConfiguration: nodeConfigDraft || nodeConfig,
                    edgeConfiguration: edgeConfigDraft || edgeConfig,
                },
            });
            return;
        }
        save({
            title: titleDraft || title,
            description: descriptionDraft || description,
            configuration: configuration,
            nodeConfiguration: nodeConfigDraft || nodeConfig,
            edgeConfiguration: edgeConfigDraft || edgeConfig,
        });
    }, [
        description,
        descriptionDraft,
        edgeConfig,
        edgeConfigDraft,
        nodeConfig,
        nodeConfigDraft,
        save,
        title,
        titleDraft,
        token,
        update,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.auditTrail,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.initialMarking,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.markingStore,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.metadata,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.name,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.supports,
        workflowConfigDraft === null || workflowConfigDraft === void 0 ? void 0 : workflowConfigDraft.type,
    ]);
    if (isAuthenticated) {
        return (<Button disabled={isUpdating || isSaving} variant="ghost" className="w-24" onClick={handleSave}>
                {isSaving || isUpdating ? (<Icon icon="blocks-shuffle-3" className="h-6 w-6"/>) : (<React.Fragment>
                        <Icon icon="humbleicons:upload" className="mr-2 h-6 w-6"/>
                        Save
                    </React.Fragment>)}
            </Button>);
    }
    return (<Popover>
            <PopoverTrigger className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm text-gray-400 font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-24">
                <Icon icon="humbleicons:upload" className="mr-2 h-6 w-6 text-gray-400"/>
                Save
            </PopoverTrigger>
            <PopoverContent>
                <p>You need to sign in to save your workflow</p>
                <Link to="/sign-in" className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground [&.active]:text-primary [&.active]:bg-accent [&.active]:font-bold">
                    <Button variant="outline">Sign In / Sign Up</Button>
                </Link>
            </PopoverContent>
        </Popover>);
};
