var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { SaveButton } from '@/components/save-button';
import { DownloadFormat } from '@/components/download-format';
import { useMutation } from '@tanstack/react-query';
import { downloadWorkflowWithConfiguration } from '@/api/workflow';
import { WorkflowConfigHelper } from '@/helpers/workflow-config.helper';
import { useSessionStorage } from '@/hooks/useSession';
import { CommentSection } from '@/components/comment-section';
import { Configuration } from '@/components/configuration';
export var TopMenu = function (_a) {
    var workflow = _a.workflow, edges = _a.edges, nodes = _a.nodes;
    var workflowConfigDraft = useSessionStorage().workflowConfigDraft;
    var _b = useMutation({
        mutationFn: downloadWorkflowWithConfiguration,
        onSuccess: function () { },
    }), isPending = _b.isPending, mutateAsync = _b.mutateAsync;
    var handleDownload = React.useCallback(function (format) { return __awaiter(void 0, void 0, void 0, function () {
        var response, url, link, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!workflowConfigDraft) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, mutateAsync({
                            format: format,
                            configuration: WorkflowConfigHelper.fromNodes(nodes, edges, workflowConfigDraft),
                        })];
                case 2:
                    response = _a.sent();
                    url = window.URL.createObjectURL(new Blob([response.data]));
                    link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', "".concat(workflowConfigDraft.name, ".").concat(format));
                    document.body.appendChild(link);
                    link.click();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [edges, mutateAsync, nodes, workflowConfigDraft]);
    return (<div className="flex flex-row gap-3 border w-full bg">
            <SaveButton token={workflow === null || workflow === void 0 ? void 0 : workflow.token}/>
            <DownloadFormat isLoading={isPending} onDownload={handleDownload}/>
            <Configuration />
            {workflow && <CommentSection workflow={workflow}/>}
        </div>);
};
