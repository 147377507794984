import { customAlphabet } from 'nanoid';
export function generateToken(tokenHelperOptions) {
    if (tokenHelperOptions === void 0) { tokenHelperOptions = {}; }
    var _a = tokenHelperOptions.size, size = _a === void 0 ? 16 : _a, _b = tokenHelperOptions.majuscule, majuscule = _b === void 0 ? true : _b, _c = tokenHelperOptions.minuscule, minuscule = _c === void 0 ? true : _c, _d = tokenHelperOptions.numeric, numeric = _d === void 0 ? true : _d, _e = tokenHelperOptions.symbol, symbol = _e === void 0 ? false : _e;
    var characters = '';
    if (majuscule) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (minuscule) {
        characters += 'abcdefghijklmnopqrstuvwxyz';
    }
    if (numeric) {
        characters += '0123456789';
    }
    if (symbol) {
        characters += '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
    }
    var nanoid = customAlphabet(characters, size);
    return nanoid();
}
