import React from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger, } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
export var Confirmation = function (_a) {
    var _b = _a.title, title = _b === void 0 ? 'Are you absolutely sure?' : _b, _c = _a.description, description = _c === void 0 ? 'This action cannot be undone. This will permanently delete your account and remove your data from our servers.' : _c, _d = _a.confirmTitle, confirmTitle = _d === void 0 ? 'Continue' : _d, _e = _a.cancelTitle, cancelTitle = _e === void 0 ? 'Cancel' : _e, _f = _a.triggerTitle, triggerTitle = _f === void 0 ? 'Delete' : _f, confirmAction = _a.confirmAction, children = _a.children;
    return (<AlertDialog>
            <AlertDialogTrigger asChild>
                {children ? children : <Button variant="outline">{triggerTitle}</Button>}
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>{description}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{cancelTitle}</AlertDialogCancel>
                    <AlertDialogAction asChild>
                        <Button onClick={confirmAction}>{confirmTitle}</Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>);
};
