import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { Icon } from '@iconify/react';
import { Card, CardContent } from '@/components/ui/card';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger, } from '@/components/ui/alert-dialog';
export var Toolbar = function (_a) {
    var addPlaceNode = _a.addPlaceNode, addTransitionNode = _a.addTransitionNode, onEmptyPanel = _a.onEmptyPanel;
    var heightSize = 'h-20';
    var widthSize = 'w-20';
    var onDragStart = function (event, nodeType) {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    return (<Card className="absolute top-[15px]" style={{ zIndex: 1 }}>
            <CardContent className="flex flex-row gap-3 py-3">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" onDragStart={function (event) { return onDragStart(event, 'place'); }} onClick={addPlaceNode} draggable>
                            <Icon icon="material-symbols:rectangle-outline-rounded" className={"text-primary ".concat(heightSize, " ").concat(widthSize)}/>
                            <span className="sr-only">Place</span>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Place</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button variant="ghost" size="icon" onDragStart={function (event) { return onDragStart(event, 'transition'); }} onClick={addTransitionNode} draggable>
                            <Icon icon="material-symbols:circle-outline" className={"text-teal-500  ".concat(heightSize, " ").concat(widthSize)}/>
                            <span className="sr-only">Transition</span>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Transition</TooltipContent>
                </Tooltip>
                <AlertDialog>
                    <AlertDialogTrigger asChild>
                        <Button variant="ghost" size="icon">
                            <Icon icon="mdi:trash-outline" className={"text-red-500  ".concat(heightSize, " ").concat(widthSize)}/>
                            <span className="sr-only">Empty</span>
                        </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                                This action cannot be undone. This will permanently delete your account and remove your
                                data from our servers.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={onEmptyPanel}>Continue</AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </CardContent>
        </Card>);
};
