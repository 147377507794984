var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useForm } from 'react-hook-form';
import { isWorkflowTransition } from '@/types/WorkflowTransition';
import { Form } from '@/components/ui/form';
import TextField from '@/components/text-field';
import Metadata from '@/app/metadata';
import Select from '@/components/select';
import Switch from '@/components/switch';
import SupportEntities from '@/app/support-entities';
import { useSessionStorage } from '@/hooks/useSession';
export var SideSection = function () {
    var _a = useSessionStorage(), workflowConfig = _a.workflowConfig, nodeConfigDraft = _a.nodeConfigDraft, title = _a.title, description = _a.description, setWorkflowConfigDraft = _a.setWorkflowConfigDraft, setTitleDraft = _a.setTitleDraft, setDescriptionDraft = _a.setDescriptionDraft;
    var form = useForm({
        defaultValues: {
            title: title,
            description: description,
            configuration: {
                name: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.name) || '',
                type: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.type) || 'state_machine',
                auditTrail: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.auditTrail) || false,
                markingStore: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.markingStore) || {
                    type: 'method',
                    property: '',
                },
                initialMarking: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.initialMarking) || '',
                metadata: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.metadata) || [],
                supports: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.supports) || [],
            },
        },
    });
    React.useEffect(function () {
        if (title) {
            form.setValue('title', title);
        }
        if (description) {
            form.setValue('description', description);
        }
        if (workflowConfig) {
            form.setValue('configuration', {
                name: workflowConfig.name,
                type: workflowConfig.type,
                auditTrail: workflowConfig.auditTrail,
                markingStore: workflowConfig.markingStore,
                initialMarking: workflowConfig.initialMarking,
                metadata: workflowConfig.metadata,
                supports: workflowConfig.supports,
            });
            form.setValue('configuration.metadata', workflowConfig.metadata);
        }
    }, [description, form, title, workflowConfig]);
    var places = React.useMemo(function () {
        return nodeConfigDraft
            .filter(function (node) { return node.type === 'place'; })
            .map(function (node) {
            return {
                label: node.data.name,
                value: node.data.name,
            };
        });
    }, [nodeConfigDraft]);
    var onSubmit = React.useCallback(function (data) {
        var placeNodes = nodeConfigDraft.reduce(function (acc, node) {
            if (node.type === 'place') {
                acc[node.id] = node.data;
            }
            return acc;
        }, {});
        var places = nodeConfigDraft
            .filter(function (node) { return node.type === 'place'; })
            .map(function (node) { return node.data; });
        var transitions = nodeConfigDraft
            .filter(function (node) { return node.type === 'transition'; })
            .map(function (node) {
            if (isWorkflowTransition(node.data)) {
                var from = node.data.from || [];
                var to = node.data.to || [];
                return __assign(__assign({}, node.data), { guard: node.data.guard || undefined, from: from.map(function (id) { return placeNodes[id].name; }), to: to.map(function (id) { return placeNodes[id].name; }) });
            }
            return node.data;
        });
        setWorkflowConfigDraft(__assign(__assign({}, data.configuration), { places: places, transitions: transitions }));
        if (data.title) {
            setTitleDraft(data.title);
        }
        if (data.description) {
            setDescriptionDraft(data.description);
        }
    }, [nodeConfigDraft, setDescriptionDraft, setTitleDraft, setWorkflowConfigDraft]);
    React.useEffect(function () {
        if (form.formState.isValid && !form.formState.isValidating) {
            onSubmit(form.getValues());
        }
    }, [form, onSubmit]);
    var handleChange = function (_a) {
        var _b;
        var _c = _a.target, name = _c.name, value = _c.value;
        if (name === 'title') {
            setTitleDraft(value);
        }
        if (name === 'description') {
            setDescriptionDraft(value);
        }
        var config = form.getValues('configuration');
        setWorkflowConfigDraft(__assign(__assign({}, config), (_b = { places: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.places) || [], transitions: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.transitions) || [] }, _b[name] = value, _b)));
    };
    var handleValueChange = function (name, value) {
        var _a;
        var config = form.getValues('configuration');
        setWorkflowConfigDraft(__assign(__assign({}, config), (_a = { places: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.places) || [], transitions: (workflowConfig === null || workflowConfig === void 0 ? void 0 : workflowConfig.transitions) || [] }, _a[name] = value, _a)));
    };
    return (<Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col gap-4 p-6">
                    <TextField control={form.control} id="title" name="title" label="Title" placeholder="Title" onChange={handleChange}/>
                    <TextField control={form.control} id="description" name="description" label="Description" placeholder="Description" onChange={handleChange} multiple/>
                    <TextField control={form.control} id="configuration.workflowName" name="configuration.name" label="Workflow name" placeholder="Workflow name" onChange={handleChange}/>
                    <Metadata control={form.control} name="configuration.metadata"/>
                    <Select control={form.control} name="configuration.type" placeholder="Select a workflow type" items={[
            { label: 'State Machine', value: 'state_machine' },
            { label: 'Workflow', value: 'workflow' },
        ]} onChange={function (event) {
            console.log({ event: event });
        }}/>
                    <Switch control={form.control} name="configuration.auditTrail" id="audit-trail" label="Audit Trail"/>
                    <div className="flex flex-col gap-2">
                        <p className="text-lg">Marking Store</p>
                        <TextField control={form.control} name="configuration.markingStore.property" label="Property" onChange={handleChange}/>
                    </div>
                    <SupportEntities control={form.control}/>
                    <Select control={form.control} name="configuration.initialMarking" placeholder="Select a initial marking" label="Initial Marking" items={places}/>
                </div>
            </form>
        </Form>);
};
