import axios from 'axios';
/**
 * Retrieves a comment with the specified ID from the server.
 *
 * @param {number} id - The ID of the comment to retrieve.
 * @returns {Promise<AxiosResponse<Comment>>} A Promise that resolves to the AxiosResponse containing the retrieved comment.
 */
export var getComment = function (id) {
    return axios.get("/api/comment/".concat(id));
};
/**
 * Sends a PUT request to update a comment.
 *
 * @param {Object} data - The data containing the comment ID and the new comment.
 * @param {number} data.id - The ID of the comment to be updated.
 * @param {Object} data.comment - The new comment to be updated.
 * @returns {Promise} A Promise that resolves to the AxiosResponse<Comment> object.
 */
export var putComment = function (data) {
    return axios.put("/api/comment/".concat(data.id), data.comment);
};
/**
 * Deletes a comment from the server.
 *
 * @param {number} id - The ID of the comment to delete.
 * @returns {Promise<AxiosResponse<Comment>>} A Promise that resolves to the Axios response containing the deleted comment.
 */
export var deleteComment = function (id) {
    return axios.delete("/api/comment/".concat(id));
};
