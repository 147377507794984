import React from 'react';
import { ThemeToggle } from '@/components/theme-toggle';
import Blogs from '@/app/blogs';
import Logo from '@/assets/logo-34.png';
import { Link } from '@tanstack/react-router';
import { SignInButton } from '@/components/sign-in-button';
var NavBar = React.memo(function () {
    return (<header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="container flex h-14 max-w-screen-2xl items-center">
                <div className="mr-4 hidden md:flex">
                    <a className="mr-6 flex items-center space-x-2" href="/">
                        <img src={Logo} alt="Symflowbuilder" width={35} height={33}/>
                        <span className="hidden text-primary font-bold sm:inline-block">SymFlow</span>
                    </a>
                    <nav className="flex items-center gap-6 text-sm">
                        <Link to="/" className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground [&.active]:text-primary [&.active]:bg-accent [&.active]:font-bold">
                            Homepage
                        </Link>
                        <Blogs />
                    </nav>
                </div>
                <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
                    <nav className="flex items-center gap-1">
                        <a href="https://www.reddit.com/r/symflow" className="mr-2">
                            <img src="https://www.redditstatic.com/desktop2x/img/favicon/apple-icon-57x57.png" alt="Reddit" width={30} height={30}/>
                        </a>
                        <ThemeToggle />
                        <SignInButton />
                    </nav>
                </div>
            </div>
        </header>);
});
export default NavBar;
