import React from 'react';
import { Button } from '@/components/ui/button';
import { Icon } from '@iconify/react';
import { useFieldArray } from 'react-hook-form';
import TextField from '@/components/text-field';
var Metadata = React.memo(function (_a) {
    var control = _a.control, name = _a.name;
    var _b = useFieldArray({
        control: control,
        name: name,
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    return (<div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
                <p className="text-blue-300">Metadata:</p>
                <Button variant="secondary" onClick={function () { return append({ name: '', value: '' }); }}>
                    <Icon icon="icons8:plus" width={24} height={24}/>
                </Button>
            </div>
            {fields.map(function (field, index) { return (<div key={field.id} className="flex gap-3">
                    <div className="flex-grow">
                        <TextField control={control} name={"".concat(name, ".").concat(index, ".name")} type="text" placeholder="Name" className="flex-grow"/>
                    </div>
                    <div className="flex-grow">
                        <TextField control={control} name={"".concat(name, ".").concat(index, ".value")} type="text" placeholder="Value"/>
                    </div>
                    <Button variant="destructive" onClick={function () { return remove(index); }}>
                        <Icon icon="tabler:trash" width={24}/>
                    </Button>
                </div>); })}
        </div>);
});
export default Metadata;
