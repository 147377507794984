var StringHelper = /** @class */ (function () {
    function StringHelper() {
    }
    StringHelper.camelCaseToSneakCase = function (str) {
        return str.replace(/[A-Z]/g, function (letter, index) {
            return index == 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
        });
    };
    StringHelper.replace = function (str) {
        return str.replace(/([~!@#$%^&*()_+=`{}\[\]|\\:;'<>,.\/? ])+/g, '-').replace(/^(-)+|(-)+$/g, '');
    };
    StringHelper.getFullName = function (user) {
        if (user) {
            return "".concat(user.firstName, " ").concat(user.lastName);
        }
        return '';
    };
    return StringHelper;
}());
export { StringHelper };
