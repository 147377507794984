import React from 'react';
import Dropzone from 'react-dropzone';
import { useBoolean } from 'react-use';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
export var FileDropzone = function (_a) {
    var onDrop = _a.onDrop, children = _a.children;
    var _b = useBoolean(false), open = _b[0], onToggle = _b[1];
    var handleDrop = React.useCallback(function (files) {
        if (onDrop) {
            onDrop(files[0]);
        }
        onToggle(false);
    }, [onDrop, onToggle]);
    var onDragEnter = React.useCallback(function () {
        if (!open) {
            onToggle(true);
        }
    }, [onToggle, open]);
    return (<React.Fragment>
            <Dropzone noClick onDrop={handleDrop} onDragEnter={onDragEnter} multiple={false}>
                {function (_a) {
            var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps;
            return (<React.Fragment>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} hidden/>
                            {children}
                            <Dialog open={open} onOpenChange={onToggle}>
                                <DialogContent aria-describedby="Drop your workflow configuration yaml file here" className="sm:max-w-[425px]">
                                    <DialogHeader>
                                        <DialogTitle>File dropzone</DialogTitle>
                                    </DialogHeader>
                                    <div className="border-dashed border-4 rounded-md w-[375px] h-[375px] flex flex-col items-center justify-center">
                                        <DialogDescription className="text-lg text-center font-bold">
                                            Drop your workflow configuration yaml file here
                                        </DialogDescription>
                                    </div>
                                </DialogContent>
                            </Dialog>
                        </div>
                    </React.Fragment>);
        }}
            </Dropzone>
        </React.Fragment>);
};
export default FileDropzone;
