import React from 'react';
import { SecurityContext } from '@/contexts/security-context';
import { useQuery } from '@tanstack/react-query';
import { getMe } from '@/api/user';
export var SecurityProvider = function (_a) {
    var children = _a.children;
    var _b = React.useState(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var data = useQuery({
        queryKey: ['me'],
        queryFn: getMe,
    }).data;
    React.useEffect(function () {
        if (data === null || data === void 0 ? void 0 : data.data) {
            setIsAuthenticated(true);
            return;
        }
        setIsAuthenticated(false);
    }, [data]);
    return (<SecurityContext.Provider value={{ isAuthenticated: isAuthenticated, currentUser: data === null || data === void 0 ? void 0 : data.data }}>
            {children}
        </SecurityContext.Provider>);
};
