import React from 'react';
import { Button } from '@/components/ui/button';
import { Icon } from '@iconify/react';
import { Input } from '@/components/ui/input';
var GraphMetadata = React.memo(function (_a) {
    var id = _a.id, metadata = _a.metadata, onAddMetadata = _a.onAddMetadata, onRemoveMetadata = _a.onRemoveMetadata, onChangeMetadata = _a.onChangeMetadata;
    var handleAddMetadata = React.useCallback(function () {
        if (onAddMetadata) {
            onAddMetadata(id);
        }
    }, [id, onAddMetadata]);
    var handleRemoveMetadata = React.useCallback(function (index) { return function () {
        if (onRemoveMetadata) {
            onRemoveMetadata(index, id);
        }
    }; }, [id, onRemoveMetadata]);
    var handleChangeMetadata = React.useCallback(function (index) { return function (e) {
        if (onChangeMetadata) {
            onChangeMetadata(e, index, id);
        }
    }; }, [id, onChangeMetadata]);
    return (<div className="flex flex-col gap-2">
                <div className="flex justify-between items-center gap-3">
                    <p className="text-blue-300">Metadata:</p>
                    <Button variant="secondary" onClick={handleAddMetadata}>
                        <Icon icon="icons8:plus" width={24} height={24}/>
                    </Button>
                </div>
                {metadata.map(function (meta, index) { return (<div key={"".concat(id, "-metadata-").concat(index)} className="flex gap-3">
                        <div className="flex-grow">
                            <Input name="name" type="text" placeholder="Name" value={meta.name} className="flex-grow"/>
                        </div>
                        <div className="flex-grow">
                            <Input name="value" type="text" placeholder="Value" value={meta.value} className="flex-grow" onChange={handleChangeMetadata(index)}/>
                        </div>
                        <Button variant="destructive" onClick={handleRemoveMetadata(index)}>
                            <Icon icon="tabler:trash" width={24}/>
                        </Button>
                    </div>); })}
            </div>);
});
export default GraphMetadata;
