import React from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { Button } from '@/components/ui/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from '@/components/ui/form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postComment } from '@/api/workflow';
import TextareaField from '@/components/textarea-field';
var schema = object({
    message: string().required('Comment is required').max(255),
});
export var SendComment = function (_a) {
    var workflow = _a.workflow;
    var queryClient = useQueryClient();
    var form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            message: '',
        },
    });
    var _b = useMutation({
        mutationFn: postComment,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['comments'] }).then(function () { return form.reset({ message: '' }); });
        },
    }), mutate = _b.mutate, isPending = _b.isPending;
    var onSubmit = React.useCallback(function (data) {
        mutate({ token: workflow.token, comment: data });
    }, [mutate, workflow.token]);
    return (<Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                <div className="p-5 rounded-lg flex flex-col gap-2">
                    <TextareaField control={form.control} name="message" label="Comment" placeholder="Add a comment..." rows={5}/>
                    <Button type="submit" disabled={isPending}>
                        Send
                    </Button>
                </div>
            </form>
        </Form>);
};
