import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import React from 'react';
import { useSessionStorage } from '@/hooks/useSession';
export var ApplyDraftConfirmation = function () {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var _b = useSessionStorage(), setWorkflowConfig = _b.setWorkflowConfig, setEdgeConfig = _b.setEdgeConfig, setNodeConfig = _b.setNodeConfig, setTitle = _b.setTitle, setDescription = _b.setDescription;
    React.useEffect(function () {
        var edgeConfigDraft = sessionStorage.getItem('edgeConfigDraft');
        var nodeConfigDraft = sessionStorage.getItem('nodeConfigDraft');
        var workflowConfigDraft = sessionStorage.getItem('workflowConfigDraft');
        var titleDraft = sessionStorage.getItem('titleDraft');
        var descriptionDraft = sessionStorage.getItem('descriptionDraft');
        var timer = setTimeout(function () {
            setOpen(!!edgeConfigDraft || !!nodeConfigDraft || !!workflowConfigDraft || !!titleDraft || !!descriptionDraft);
        }, 500);
        return function () {
            clearTimeout(timer);
        };
    }, []);
    var handleDeleteDraft = React.useCallback(function () {
        sessionStorage.removeItem('workflowConfigDraft');
        sessionStorage.removeItem('nodeConfigDraft');
        sessionStorage.removeItem('edgeConfigDraft');
        sessionStorage.removeItem('titleDraft');
        sessionStorage.removeItem('descriptionDraft');
        setOpen(false);
    }, []);
    var handleApplyChanges = React.useCallback(function () {
        var workflowConfigDraft = sessionStorage.getItem('workflowConfigDraft');
        if (workflowConfigDraft) {
            setWorkflowConfig(JSON.parse(workflowConfigDraft));
        }
        var nodeConfigDraft = sessionStorage.getItem('nodeConfigDraft');
        if (nodeConfigDraft) {
            setNodeConfig(JSON.parse(nodeConfigDraft));
        }
        var edgeConfigDraft = sessionStorage.getItem('edgeConfigDraft');
        if (edgeConfigDraft) {
            setEdgeConfig(JSON.parse(edgeConfigDraft));
        }
        var titleDraft = sessionStorage.getItem('titleDraft');
        if (titleDraft) {
            setTitle(titleDraft);
        }
        var descriptionDraft = sessionStorage.getItem('descriptionDraft');
        if (descriptionDraft) {
            setDescription(descriptionDraft);
        }
        handleDeleteDraft();
    }, [handleDeleteDraft, setDescription, setEdgeConfig, setNodeConfig, setTitle, setWorkflowConfig]);
    return (<AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Confirmation</AlertDialogTitle>
                    <AlertDialogDescription>This workflow has previously unsaved changes.</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                        <Button variant="outline" onClick={handleDeleteDraft}>
                            Discard
                        </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction asChild>
                        <Button onClick={handleApplyChanges}>Apply changes</Button>
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>);
};
