'use client';
import React from 'react';
import { SessionStorageContext } from '@/contexts/session-storage-context';
var SessionStorageProvider = React.memo(function (_a) {
    var children = _a.children;
    var _b = React.useState(''), title = _b[0], setTitle = _b[1];
    var _c = React.useState(''), titleDraft = _c[0], setTitleDraft = _c[1];
    var _d = React.useState(''), description = _d[0], setDescription = _d[1];
    var _e = React.useState(''), descriptionDraft = _e[0], setDescriptionDraft = _e[1];
    var _f = React.useState(undefined), workflowConfig = _f[0], setWorkflowConfig = _f[1];
    var _g = React.useState(undefined), workflowConfigDraft = _g[0], setWorkflowConfigDraft = _g[1];
    var _h = React.useState([]), nodeConfig = _h[0], setNodeConfig = _h[1];
    var _j = React.useState([]), nodeConfigDraft = _j[0], setNodeConfigDraft = _j[1];
    var _k = React.useState([]), edgeConfig = _k[0], setEdgeConfig = _k[1];
    var _l = React.useState([]), edgeConfigDraft = _l[0], setEdgeConfigDraft = _l[1];
    var handleWorkflowConfigDraft = React.useCallback(function (config) {
        setWorkflowConfigDraft(config);
        if (config) {
            sessionStorage.setItem('workflowConfigDraft', JSON.stringify(config));
            return;
        }
        sessionStorage.removeItem('workflowConfigDraft');
    }, []);
    var handleNodeConfigDraft = React.useCallback(function (config) {
        setNodeConfigDraft(config);
        sessionStorage.setItem('nodeConfigDraft', JSON.stringify(config));
    }, []);
    var handleEdgeConfigDraft = React.useCallback(function (config) {
        setEdgeConfigDraft(config);
        sessionStorage.setItem('edgeConfigDraft', JSON.stringify(config));
    }, []);
    var handleTitleDraft = React.useCallback(function (title) {
        setTitleDraft(title);
        sessionStorage.setItem('titleDraft', title);
    }, []);
    var handleDescriptionDraft = React.useCallback(function (description) {
        setDescriptionDraft(description);
        sessionStorage.setItem('descriptionDraft', description);
    }, []);
    var onEmpty = React.useCallback(function () {
        sessionStorage.removeItem('workflowConfigDraft');
        sessionStorage.removeItem('nodeConfigDraft');
        sessionStorage.removeItem('edgeConfigDraft');
        sessionStorage.removeItem('titleDraft');
        sessionStorage.removeItem('descriptionDraft');
        setWorkflowConfigDraft(undefined);
        setNodeConfigDraft([]);
        setEdgeConfigDraft([]);
        setTitleDraft('');
        setDescriptionDraft('');
    }, []);
    return (<React.Fragment>
            <SessionStorageContext.Provider value={{
            title: title,
            titleDraft: titleDraft,
            description: description,
            descriptionDraft: descriptionDraft,
            edgeConfig: edgeConfig,
            edgeConfigDraft: edgeConfigDraft,
            nodeConfig: nodeConfig,
            nodeConfigDraft: nodeConfigDraft,
            workflowConfig: workflowConfig,
            workflowConfigDraft: workflowConfigDraft,
            setEdgeConfig: setEdgeConfig,
            setEdgeConfigDraft: handleEdgeConfigDraft,
            setNodeConfig: setNodeConfig,
            setNodeConfigDraft: handleNodeConfigDraft,
            setWorkflowConfig: setWorkflowConfig,
            setWorkflowConfigDraft: handleWorkflowConfigDraft,
            setDescription: setDescription,
            setDescriptionDraft: handleDescriptionDraft,
            setTitle: setTitle,
            setTitleDraft: handleTitleDraft,
            empty: onEmpty,
        }}>
                {children}
            </SessionStorageContext.Provider>
        </React.Fragment>);
});
export default SessionStorageProvider;
