import React from 'react';
import { useFieldArray } from 'react-hook-form';
import TextField from '@/components/text-field';
import { Button } from '@/components/ui/button';
var SupportEntities = React.memo(function (_a) {
    var control = _a.control;
    var _b = useFieldArray({
        control: control,
        name: 'supports',
    }), fields = _b.fields, append = _b.append, remove = _b.remove;
    var onAppend = React.useCallback(function () {
        append({ entityName: '' });
    }, [append]);
    return (<div className="flex flex-col gap-3">
            <p className="text-lg">Support Entities</p>
            {fields.map(function (field, index) { return (<div key={field.id} className="flex gap-3 border rounded-md">
                    <div className="flex-grow">
                        <TextField control={control} name={"configuration.supports.".concat(index, ".entityName")} type="text" placeholder="Support Entity Name" key={field.id}/>
                    </div>
                    <Button variant="destructive" onClick={function () { return remove(index); }}>
                        Remove
                    </Button>
                </div>); })}
            <Button variant="secondary" onClick={onAppend}>
                Add Support Entity
            </Button>
        </div>);
});
export default SupportEntities;
