var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useId } from 'react';
import { Select as BaseSelect, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
var Select = React.memo(function (_a) {
    var id = _a.id, control = _a.control, label = _a.label, name = _a.name, className = _a.className, placeholder = _a.placeholder, items = _a.items, props = __rest(_a, ["id", "control", "label", "name", "className", "placeholder", "items"]);
    var localId = useId();
    return (<FormField control={control} name={name} render={function (_a) {
            var field = _a.field;
            return (<FormItem>
                    {label && <FormLabel htmlFor={id || localId}>{label}</FormLabel>}
                    <BaseSelect disabled={props.disabled} onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                            <SelectTrigger {...props} id={id || localId} className={cn('flex-1', className)}>
                                <SelectValue placeholder={placeholder}/>
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                            {items.map(function (item, index) { return (<SelectItem value={item.value} key={"".concat(id || localId, "-select-item-").concat(index)}>
                                    {item.label}
                                </SelectItem>); })}
                        </SelectContent>
                    </BaseSelect>
                    <FormMessage />
                </FormItem>);
        }}/>);
});
export default Select;
