import React from 'react';
import { getRectOfNodes, getTransformForBounds, Panel, useReactFlow } from 'reactflow';
import { Button } from '@/components/ui/button';
import { toPng } from 'html-to-image';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
function downloadImage(dataUrl) {
    var a = document.createElement('a');
    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
}
var imageWidth = 1024;
var imageHeight = 768;
var ExportImageButton = React.memo(function () {
    var getNodes = useReactFlow().getNodes;
    var _a = React.useState(imageWidth), width = _a[0], setWidth = _a[1];
    var _b = React.useState(imageHeight), height = _b[0], setHeight = _b[1];
    var onClick = React.useCallback(function () {
        var nodesBounds = getRectOfNodes(getNodes());
        var transform = getTransformForBounds(nodesBounds, width, height, 0.5, 2);
        toPng(document.querySelector('.react-flow__viewport'), {
            backgroundColor: '#1a365d',
            width: width,
            height: height,
            style: {
                width: "".concat(width),
                height: "".concat(height),
                transform: "translate(".concat(transform[0], "px, ").concat(transform[1], "px) scale(").concat(transform[2], ")"),
            },
        }).then(downloadImage);
    }, [getNodes, height, width]);
    return (<Panel position="top-right">
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="ghost">Export Image</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Dimension</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="width" className="text-right">
                                Width
                            </Label>
                            <Input id="width" value={width} className="col-span-3" onChange={function (e) {
            setWidth(Number(e.target.value));
        }}/>
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="height" className="text-right">
                                Height
                            </Label>
                            <Input id="height" value={height} className="col-span-3" onChange={function (e) {
            setHeight(Number(e.target.value));
        }}/>
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={onClick}>Download</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </Panel>);
});
export default ExportImageButton;
