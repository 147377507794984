import axios from 'axios';
/**
 * Retrieves a list of workflows from the server.
 *
 * @returns {Promise<AxiosResponse<Workflow[]>>} A Promise that resolves to a Response object representing a list of workflows.
 */
export var getWorkflows = function () {
    return axios.get("/api/workflows");
};
/**
 * Retrieves the workflow data for a given token.
 *
 * @param {string} token - The token of the workflow.
 * @returns {Promise<AxiosResponse<Workflow>>} A Promise that resolves to the workflow data.
 */
export var getWorkflow = function (token) {
    return axios.get("/api/workflows/".concat(token));
};
/**
 * Sends a POST request to create a new workflow.
 *
 * @param {Workflow} workflow - The workflow data to be created.
 * @returns {Promise<AxiosResponse<Workflow>>} - A Promise that resolves to the created workflow response.
 */
export var postWorkflow = function (workflow) {
    return axios.post("/api/workflows", workflow);
};
/**
 * Updates a workflow by making a PUT request to the API.
 *
 * @param {Object} params - The parameters for updating the workflow.
 * @param {string} params.token - The token of the workflow to be updated.
 * @param {Object} params.workflow - The updated workflow object, excluding the 'id' and 'token' properties.
 * @returns {Promise<AxiosResponse<Workflow>>} A Promise that resolves to the Axios response containing the updated workflow.
 */
export var putWorkflow = function (_a) {
    var token = _a.token, workflow = _a.workflow;
    return axios.put("/api/workflows/".concat(token), workflow);
};
/**
 * Deletes a workflow.
 *
 * @param {string} token - The token of the workflow to be deleted.
 * @returns {Promise<AxiosResponse<Workflow>>} - A promise that resolves to the Axios response containing the deleted workflow.
 */
export var deleteWorkflow = function (token) {
    return axios.delete("/api/workflows/".concat(token));
};
/**
 * Downloads a workflow in the specified format.
 *
 * @param {Object} options - The options for downloading the workflow.
 * @param {string} options.token - The token of the workflow.
 * @param {string} [options.format='xml'] - The format of the workflow (default is 'xml').
 *
 * @returns {Promise<AxiosResponse<Blob>>} A Promise that resolves with the downloaded workflow as a Blob.
 */
export var downloadWorkflow = function (_a) {
    var token = _a.token, _b = _a.format, format = _b === void 0 ? 'xml' : _b;
    return axios.get("/api/workflows/".concat(token, "/downloads.").concat(format), { responseType: 'blob' });
};
/**
 * Downloads a workflow with the given configuration in the specified format.
 *
 * @param {Object} options - The options for the download workflow request.
 * @param {string} options.format - The format in which the workflow should be downloaded.
 * @param {WorkflowConfiguration} options.configuration - The configuration of the workflow.
 * @returns {Promise} - A promise that resolves to the downloaded workflow as a blob.
 */
export var downloadWorkflowWithConfiguration = function (_a) {
    var format = _a.format, configuration = _a.configuration;
    return axios.post("/api/workflows/downloads.".concat(format), { configuration: configuration }, {
        responseType: 'blob',
    });
};
/**
 * Uploads a workflow file to the server.
 *
 * @param {File} file - The workflow file to be uploaded.
 * @returns {Promise<AxiosResponse<Workflow>>} - A promise that resolves to the uploaded workflow.
 */
export var uploadWorkflow = function (file) {
    var formData = new FormData();
    formData.append('file', file);
    return axios.post("/api/workflows/uploads", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
/**
 * Retrieves the configuration for a workflow.
 *
 * @param {string} token - The token of the workflow.
 * @returns {Promise<AxiosResponse<WorkflowConfiguration>} - A promise that resolves to the workflow configuration.
 */
export var getWorkflowConfiguration = function (token) {
    return axios.get("/api/workflows/".concat(token, "/configuration"));
};
/**
 * Sends a POST request to the server to create a new comment for a workflow.
 *
 * @param {Object} data - The data object containing the token and comment information.
 * @param {string} data.token - The token of the workflow where the comment will be added.
 * @param {NewComment} data.comment - The new comment object to be added to the workflow.
 *
 * @returns {Promise<AxiosResponse<Comment>>} A Promise that resolves to the response containing the created comment.
 */
export var postComment = function (data) {
    return axios.post("/api/workflows/".concat(data.token, "/comments"), data.comment);
};
/**
 * Retrieves comments for a given token.
 *
 * @param {string} token - The token identifier.
 * @returns {Promise<AxiosResponse<Comment[]>>} A promise containing the response object with an array of comments.
 */
export var getComments = function (token) {
    return axios.get("/api/workflows/".concat(token, "/comments"));
};
