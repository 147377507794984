import React from 'react';
import { Link } from '@tanstack/react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger, } from '@/components/ui/dropdown-menu';
import { Icon } from '@iconify/react';
import { Button } from '@/components/ui/button';
import { logout } from '@/api/logout';
import { useSecurity } from '@/hooks/useSecurity';
export var SignInButton = function () {
    var queryClient = useQueryClient();
    var isAuthenticated = useSecurity().isAuthenticated;
    var mutation = useMutation({
        mutationFn: logout,
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: ['me'] }).then();
        },
    });
    var handleLogout = function () {
        if (isAuthenticated) {
            mutation.mutate();
        }
    };
    if (isAuthenticated) {
        return (<DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline">
                        <Icon icon="codicon:account"/>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem>
                        <Link to="/users/profile">Profile</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link to="/users/access-keys">Access Keys</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                        <Link to="/users/workflows">Workflows</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleLogout}>Sign Out</DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>);
    }
    return (<Link to="/sign-in" className="block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground [&.active]:text-primary [&.active]:bg-accent [&.active]:font-bold">
            <Button variant="ghost">Sign In / Sign Up</Button>
        </Link>);
};
