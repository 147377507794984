var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useId } from 'react';
import { Label } from '@/components/ui/label';
import { Switch as BaseSwitch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';
import { Controller } from 'react-hook-form';
var Switch = React.memo(function (_a) {
    var id = _a.id, control = _a.control, className = _a.className, name = _a.name, label = _a.label, props = __rest(_a, ["id", "control", "className", "name", "label"]);
    var localId = useId();
    return (<Controller control={control} name={name} render={function (_a) {
            var field = _a.field;
            return (<div className={cn('flex items-center space-x-2', className)}>
                    <BaseSwitch {...props} id={id || localId} checked={field.value} onCheckedChange={function (checked) {
                    field.onChange(checked);
                    props.onCheckedChange && props.onCheckedChange(checked);
                }} onChange={function (event) {
                    props.onChange && props.onChange(event);
                }}/>
                    {label && (<Label htmlFor={id || localId} id={id || localId}>
                            {label}
                        </Label>)}
                </div>);
        }}></Controller>);
});
export default Switch;
