var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { addEdge, Background, Controls, MarkerType, MiniMap, ReactFlow, ReactFlowProvider, useEdgesState, useNodesState, } from 'reactflow';
import 'reactflow/dist/style.css';
import PlaceNode from '@/components/place-node';
import TransitionNode from '@/components/transition-node';
import { isWorkflowTransition } from '@/types/WorkflowTransition';
import { generateToken } from '@/helpers/token.helper';
import ExportImageButton from '@/components/export-image-button';
import { Toolbar } from '@/components/toolbar';
import { TopMenu } from '@/components/top-menu';
import { useSessionStorage } from '@/hooks/useSession';
import { ApplyDraftConfirmation } from '@/components/apply-draft-confirmation';
import ElkConstructor from 'elkjs';
export var GraphBuilder = React.memo(function (_a) {
    var nodeConfig = _a.nodeConfig, edgeConfig = _a.edgeConfig, workflowConfig = _a.workflowConfig, workflow = _a.workflow;
    var reactFlowWrapper = React.useRef(null);
    var _b = useSessionStorage(), setEdgeConfigDraft = _b.setEdgeConfigDraft, setNodeConfigDraft = _b.setNodeConfigDraft;
    var _c = useNodesState(nodeConfig || []), nodes = _c[0], setNodes = _c[1], onNodesChange = _c[2];
    var _d = useEdgesState(edgeConfig || []), edges = _d[0], setEdges = _d[1], onEdgesChange = _d[2];
    var _e = React.useState(), reactFlowInstance = _e[0], setReactFlowInstance = _e[1];
    React.useEffect(function () {
        if (nodeConfig) {
            setNodes(nodeConfig || []);
        }
        if (edgeConfig) {
            setEdges(edgeConfig || []);
        }
    }, [edgeConfig, nodeConfig, setEdges, setNodes]);
    React.useEffect(function () {
        if (workflowConfig && (nodeConfig === null || nodeConfig === void 0 ? void 0 : nodeConfig.length) === 0 && (edgeConfig === null || edgeConfig === void 0 ? void 0 : edgeConfig.length) === 0) {
            var x_1 = 0;
            var y_1 = 0;
            var places_1 = {};
            var placeNodes = workflowConfig.places.map(function (place) {
                var token = generateToken({ size: 16 });
                var placeName = "place_".concat(place.name, "_").concat(token);
                x_1 = x_1 + 400;
                places_1[place.name] = placeName;
                return {
                    id: placeName,
                    position: { x: x_1, y: y_1 },
                    data: place,
                    type: 'place',
                };
            });
            x_1 = 200;
            y_1 = 0;
            var transitions_1 = {};
            var transitionNodes_1 = [];
            workflowConfig.transitions.forEach(function (transition) {
                var token = generateToken({ size: 16 });
                var transitionName = "transition_".concat(transition.name, "_").concat(token);
                x_1 = x_1 + 400;
                transitions_1[transition.name] = transitionName;
                transitionNodes_1.push({
                    id: transitionName,
                    position: { x: x_1, y: y_1 },
                    data: __assign(__assign({}, transition), { from: transition.from.map(function (from) { return places_1[from]; }), to: transition.to.map(function (to) { return places_1[to]; }) }),
                    type: 'transition',
                });
            });
            var transitionEdges_1 = [];
            workflowConfig.transitions.forEach(function (transition) {
                transition.from.forEach(function (from) {
                    transitionEdges_1.push({
                        id: "edge_".concat(from, "_").concat(transition.name),
                        source: places_1[from],
                        target: transitions_1[transition.name],
                        type: 'smoothstep',
                        animated: true,
                        style: { strokeWidth: 5 },
                    });
                });
                transition.to.forEach(function (to) {
                    transitionEdges_1.push({
                        id: "edge_".concat(transition.name, "_").concat(to),
                        source: transitions_1[transition.name],
                        target: places_1[to],
                        type: 'smoothstep',
                        animated: true,
                        style: { strokeWidth: 5 },
                    });
                });
            });
            var nodes_1 = __spreadArray(__spreadArray([], placeNodes, true), transitionNodes_1, true);
            var edges_1 = __spreadArray([], transitionEdges_1, true);
            var elk = new ElkConstructor();
            var graph = {
                id: 'root',
                layoutOptions: {
                    'elk.algorithm': 'layered',
                    'elk.direction': 'RIGHT',
                    'nodePlacement.strategy': 'SIMPLE',
                    'elk.spacing.nodeNode': '100', // Increase the distance between nodes
                    'elk.layered.spacing.nodeNodeBetweenLayers': '200', // Increase the distance between layers
                },
                children: nodes_1.map(function (node) { return (__assign(__assign({}, node), { width: 210, height: 150 })); }),
                edges: edges_1.map(function (edge) { return (__assign(__assign({}, edge), { sources: [edge.source], targets: [edge.target] })); }),
            };
            elk.layout(graph).then(function (layout) {
                if (layout.children) {
                    var newNodes = layout.children.map(function (child) { return (__assign(__assign({}, nodes_1.find(function (n) { return n.id === child.id; })), { position: { x: child.x, y: child.y } })); });
                    if (newNodes.length > 0) {
                        setNodes(newNodes);
                        setNodeConfigDraft(newNodes);
                    }
                }
                if (layout.edges) {
                    var newEdges = layout.edges.map(function (edge) { return (__assign(__assign({}, edges_1.find(function (e) { return e.id === edge.id; })), { source: edge.sources[0], target: edge.targets[0] })); });
                    if (newEdges.length > 0) {
                        setEdges(newEdges);
                        setEdgeConfigDraft(newEdges);
                    }
                }
            });
        }
    }, [
        workflowConfig,
        setEdgeConfigDraft,
        setEdges,
        setNodeConfigDraft,
        setNodes,
        nodeConfig === null || nodeConfig === void 0 ? void 0 : nodeConfig.length,
        edgeConfig === null || edgeConfig === void 0 ? void 0 : edgeConfig.length,
    ]);
    var isValidConnection = React.useCallback(function (connection) { var _a, _b; return !(((_a = connection.target) === null || _a === void 0 ? void 0 : _a.includes('place')) && ((_b = connection.source) === null || _b === void 0 ? void 0 : _b.includes('place'))); }, []);
    var onConnect = React.useCallback(function (params) {
        setEdges(function (prevEdges) {
            var nextEdges = addEdge(__assign(__assign({}, params), { animated: true, type: 'smoothstep', style: { strokeWidth: 5 }, markerEnd: { type: MarkerType.ArrowClosed } }), prevEdges);
            setEdgeConfigDraft(nextEdges);
            return nextEdges;
        });
        var tmpNodes = __spreadArray([], nodes, true);
        var sourceIndex = tmpNodes.findIndex(function (node) { return node.id === params.source; });
        var targetIndex = tmpNodes.findIndex(function (node) { return node.id === params.target; });
        var source = tmpNodes[sourceIndex];
        var target = tmpNodes[targetIndex];
        if (target &&
            source &&
            source.type === 'place' &&
            target.type === 'transition' &&
            isWorkflowTransition(target.data)) {
            target.data.from = (target.data.from ? __spreadArray(__spreadArray([], target.data.from, true), [source.id], false) : [source.id]).filter(function (value, index, array) { return array.indexOf(value) === index; });
            tmpNodes[targetIndex] = target;
        }
        if (target &&
            source &&
            source.type === 'transition' &&
            target.type === 'place' &&
            isWorkflowTransition(source.data)) {
            source.data.to = (source.data.to ? __spreadArray(__spreadArray([], source.data.to, true), [target.id], false) : [target.id]).filter(function (value, index, array) { return array.indexOf(value) === index; });
            tmpNodes[targetIndex] = target;
        }
        setNodes(tmpNodes);
        setNodeConfigDraft(tmpNodes);
    }, [nodes, setEdgeConfigDraft, setEdges, setNodeConfigDraft, setNodes]);
    var addPlaceNode = React.useCallback(function () {
        setNodes(function (prevState) {
            var nextNodes = __spreadArray(__spreadArray([], prevState, true), [
                {
                    id: "place_".concat(generateToken()),
                    position: { x: 100, y: 100 },
                    data: { name: 'place', metadata: [] },
                    type: 'place',
                },
            ], false);
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var addTransitionNode = React.useCallback(function () {
        setNodes(function (prevState) {
            var nextNodes = __spreadArray(__spreadArray([], prevState, true), [
                {
                    id: "transition_".concat(generateToken()),
                    position: { x: 100, y: 100 },
                    data: { name: 'transition', to: [], from: [], metadata: [], guard: '' },
                    type: 'transition',
                },
            ], false);
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var onChange = React.useCallback(function (e, id) {
        setNodes(function (prevState) {
            var nextNodes = prevState.map(function (node) {
                var _a;
                if (node.id === id) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), (_a = {}, _a[e.target.name] = e.target.value, _a)) });
                }
                return node;
            });
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var handleEdgeChange = React.useCallback(function (changes) {
        changes.forEach(function (change) {
            if (change.type === 'remove') {
                var ids = change.id.split('-').splice(1);
                var placeIndex = ids.findIndex(function (id) { return id.includes('place'); });
                var transitionIndex = ids.findIndex(function (id) { return id.includes('transition'); });
                var placeId_1 = ids[placeIndex];
                var transitionId_1 = ids[transitionIndex];
                setNodes(function (prevNodes) {
                    var _a, _b;
                    var placeIndex = prevNodes.findIndex(function (node) { return node.id === placeId_1; });
                    var transitionIndex = prevNodes.findIndex(function (node) { return node.id === transitionId_1; });
                    var place = prevNodes[placeIndex];
                    var transition = prevNodes[transitionIndex];
                    if (placeIndex > transitionIndex &&
                        place &&
                        transition &&
                        isWorkflowTransition(transition.data)) {
                        transition.data.to = (_a = transition.data.to) === null || _a === void 0 ? void 0 : _a.filter(function (id) { return id !== placeId_1; });
                        prevNodes[transitionIndex] = transition;
                    }
                    if (placeIndex < transitionIndex &&
                        place &&
                        transition &&
                        isWorkflowTransition(transition.data)) {
                        transition.data.from = (_b = transition.data.from) === null || _b === void 0 ? void 0 : _b.filter(function (id) { return id !== placeId_1; });
                        prevNodes[transitionIndex] = transition;
                    }
                    prevNodes[transitionIndex] = transition;
                    setNodeConfigDraft(prevNodes);
                    return prevNodes;
                });
            }
        });
        onEdgesChange(changes);
    }, [onEdgesChange, setNodeConfigDraft, setNodes]);
    var onAddMetadata = React.useCallback(function (id) {
        setNodes(function (prevNodes) {
            var nextNodes = prevNodes.map(function (node) {
                if (node.id === id) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { metadata: __spreadArray(__spreadArray([], (node.data.metadata || []), true), [{ name: '', value: '' }], false) }) });
                }
                return node;
            });
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var onRemoveMetadata = React.useCallback(function (index, id) {
        setNodes(function (prevNodes) {
            var nextNodes = prevNodes.map(function (node) {
                var _a;
                if (node.id === id) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { metadata: (_a = node.data.metadata) === null || _a === void 0 ? void 0 : _a.filter(function (_, idx) { return idx !== index; }) }) });
                }
                return node;
            });
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var onChangeMetadata = React.useCallback(function (e, index, id) {
        setNodes(function (prevNodes) {
            var nextNodes = prevNodes.map(function (node) {
                var _a;
                if (node.id === id) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { metadata: (_a = node.data.metadata) === null || _a === void 0 ? void 0 : _a.map(function (meta, idx) {
                                var _a;
                                if (idx === index) {
                                    return __assign(__assign({}, meta), (_a = {}, _a[e.target.name] = e.target.value, _a));
                                }
                                return meta;
                            }) }) });
                }
                return node;
            });
            setNodeConfigDraft(nextNodes);
            return nextNodes;
        });
    }, [setNodeConfigDraft, setNodes]);
    var NodeTypes = React.useMemo(function () { return ({
        place: function (props) { return (<PlaceNode {...props} onChange={onChange} onAddMetadata={onAddMetadata} onRemoveMetadata={onRemoveMetadata} onChangeMetadata={onChangeMetadata}/>); },
        transition: function (props) { return (<TransitionNode {...props} onChange={onChange} onAddMetadata={onAddMetadata} onRemoveMetadata={onRemoveMetadata} onChangeMetadata={onChangeMetadata}/>); },
    }); }, [onAddMetadata, onChangeMetadata, onChange, onRemoveMetadata]);
    var onEmptyPanel = React.useCallback(function () {
        setNodes([]);
        setEdges([]);
        setEdgeConfigDraft([]);
        setNodeConfigDraft([]);
    }, [setEdgeConfigDraft, setEdges, setNodeConfigDraft, setNodes]);
    var onDragOver = React.useCallback(function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);
    var onDrop = React.useCallback(function (event) {
        event.preventDefault();
        var type = event.dataTransfer.getData('application/reactflow');
        if (typeof type === 'undefined' || !type) {
            return;
        }
        if (reactFlowInstance) {
            var position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            var newNode = {
                id: "".concat(type, "_").concat(generateToken()),
                type: type,
                position: position,
                data: { name: type, metadata: [] },
            };
            var tmpNodes = __spreadArray(__spreadArray([], nodes, true), [newNode], false);
            setNodes(tmpNodes);
            setNodeConfigDraft(tmpNodes);
        }
    }, [nodes, reactFlowInstance, setNodeConfigDraft, setNodes]);
    var handleNodesChange = React.useCallback(function (changes) {
        changes.forEach(function (change) {
            if (change.type === 'position') {
                setNodes(function (prevNodes) {
                    var nextNodes = prevNodes.map(function (node) {
                        if (node.id === change.id && change.position) {
                            return __assign(__assign({}, node), { position: change.position });
                        }
                        return node;
                    });
                    setNodeConfigDraft(nextNodes);
                    return nextNodes;
                });
            }
        });
        onNodesChange(changes);
    }, [onNodesChange, setNodeConfigDraft, setNodes]);
    return (<React.Fragment>
            <TopMenu workflow={workflow} edges={edges} nodes={nodes}/>
            <ReactFlowProvider>
                <div ref={reactFlowWrapper} className="reactflow-wrapper flex flex-col items-center justify-center gap-3 relative" style={{ height: '89vh' }}>
                    <Toolbar addPlaceNode={addPlaceNode} addTransitionNode={addTransitionNode} onEmptyPanel={onEmptyPanel}/>
                    <ReactFlow nodes={nodes} edges={edges} nodeTypes={NodeTypes} onNodesChange={handleNodesChange} onEdgesChange={handleEdgeChange} onConnect={onConnect} isValidConnection={isValidConnection} onInit={setReactFlowInstance} onDrop={onDrop} onDragOver={onDragOver}>
                        <Controls />
                        <MiniMap className="dark:bg-background" zoomable pannable position="bottom-right"/>
                        <Background gap={25} size={2}/>
                        <ExportImageButton />
                    </ReactFlow>
                </div>
            </ReactFlowProvider>
            <ApplyDraftConfirmation />
        </React.Fragment>);
});
export default GraphBuilder;
