var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isWorkflowTransition } from '@/types/WorkflowTransition';
var WorkflowConfigHelper = /** @class */ (function () {
    function WorkflowConfigHelper() {
    }
    /**
     * Transforms a set of workflow nodes and edges into a WorkflowConfiguration object.
     *
     * @param {Node<WorkflowPlace | WorkflowTransition>[]} nodes - The list of workflow nodes which can be either places or transitions.
     * @param {Edge[]} edges - The list of edges connecting nodes.
     * @param {Omit<WorkflowConfiguration, 'places' | 'transitions'>} data - The base configuration data excluding 'places' and 'transitions'.
     * @returns {WorkflowConfiguration} The resulting WorkflowConfiguration including places and transitions.
     */
    WorkflowConfigHelper.fromNodes = function (nodes, edges, data) {
        var placeNodes = nodes.reduce(function (acc, node) {
            if (node.type === 'place') {
                acc[node.id] = node.data;
            }
            return acc;
        }, {});
        var places = nodes.filter(function (node) { return node.type === 'place'; }).map(function (node) { return node.data; });
        var transitions = nodes
            .filter(function (node) { return node.type === 'transition'; })
            .map(function (node) {
            if (isWorkflowTransition(node.data)) {
                var from = node.data.from || [];
                var to = node.data.to || [];
                return __assign(__assign({}, node.data), { guard: node.data.guard || undefined, from: from.map(function (id) { return placeNodes[id].name; }), to: to.map(function (id) { return placeNodes[id].name; }) });
            }
            return __assign(__assign({}, node.data), { guard: undefined, from: [], to: [] });
        });
        edges
            .filter(function (edge) { return edge.source && edge.target; })
            .forEach(function (edge) {
            var source = nodes.find(function (node) { return node.id === edge.source; });
            var target = nodes.find(function (node) { return node.id === edge.target; });
            if (source && target && source.type === 'place' && target.type === 'transition') {
                var transition = transitions.find(function (t) { return t.name === target.data.name; });
                if (transition) {
                    transition.from.push(source.data.name);
                }
            }
            if (source && target && source.type === 'transition' && target.type === 'place') {
                var transition = transitions.find(function (t) { return t.name === source.data.name; });
                if (transition) {
                    transition.to.push(target.data.name);
                }
            }
        });
        return __assign(__assign({}, data), { places: places, transitions: transitions });
    };
    /**
     * Converts a YAML configuration object to a WorkflowConfiguration object.
     * @param {WorkflowYamlConfiguration} yamlConfig - The YAML configuration object.
     * @returns {WorkflowConfiguration} - The converted WorkflowConfiguration object.
     */
    WorkflowConfigHelper.toObject = function (yamlConfig) {
        var workflows = yamlConfig.framework.workflows;
        var workflowName = Object.keys(workflows)[0];
        var workflow = Object.values(workflows)[0];
        var workflowType = workflow.type;
        var places = workflow.places;
        var metadata = workflow.metadata;
        var workflowMetadata = [];
        if (metadata) {
            Object.keys(metadata).forEach(function (key) {
                workflowMetadata.push({ name: key, value: metadata[key] });
            });
        }
        var workflowPlaces = Object.keys(places).map(function (key) {
            var place = places[key];
            var workflowPlace = { name: key };
            var metadata = [];
            if (place && place.metadata) {
                var placeMetadata_1 = place.metadata;
                Object.keys(place.metadata).forEach(function (meta) {
                    metadata.push({ name: meta, value: placeMetadata_1[meta] });
                });
                workflowPlace.metadata = metadata;
            }
            return workflowPlace;
        });
        var transitions = workflow.transitions;
        var workflowTransitions = [];
        if (transitions) {
            Object.keys(transitions).forEach(function (key) {
                var transition = transitions[key];
                var from = transition.from;
                var to = transition.to;
                var metadata = [];
                if (transition.metadata) {
                    var transitionMetadata_1 = transition.metadata;
                    Object.keys(transition.metadata).forEach(function (meta) {
                        metadata.push({ name: meta, value: transitionMetadata_1[meta] });
                    });
                }
                workflowTransitions.push({
                    name: key,
                    from: Array.isArray(from) ? from : [from],
                    to: Array.isArray(to) ? to : [to],
                    guard: transition.guard,
                    metadata: metadata,
                });
            });
        }
        return {
            name: workflowName,
            type: workflowType,
            auditTrail: workflow.audit_trail.enabled,
            places: workflowPlaces,
            initialMarking: workflow.initial_marking,
            supports: workflow.supports.map(function (support) { return ({ entityName: support }); }),
            metadata: workflowMetadata,
            markingStore: workflow.marking_store,
            eventsToDispatch: workflow.events_to_dispatch,
            transitions: workflowTransitions,
        };
    };
    /**
     * Transforms WorkflowConfiguration object into WorkflowConfigYaml object.
     * @param {WorkflowConfiguration} config - The WorkflowConfiguration object to be transformed.
     * @returns {WorkflowYamlConfiguration} The transformed WorkflowConfigYaml object.
     */
    WorkflowConfigHelper.toYaml = function (config) {
        var _a;
        var name = config.name, metadata = config.metadata, eventsToDispatch = config.eventsToDispatch, auditTrail = config.auditTrail, places = config.places, supports = config.supports, transitions = config.transitions, initialMarking = config.initialMarking, markingStore = config.markingStore, data = __rest(config, ["name", "metadata", "eventsToDispatch", "auditTrail", "places", "supports", "transitions", "initialMarking", "markingStore"]);
        var realPlaces = {};
        places.forEach(function (place) {
            var metadata = {};
            if (place.metadata && place.metadata.length > 0) {
                place.metadata.forEach(function (meta) {
                    metadata[meta.name] = meta.value;
                });
            }
            realPlaces[place.name] = Object.keys(metadata).length > 0 ? { metadata: metadata } : null;
        });
        var realTransitions = transitions.length > 0 ? {} : undefined;
        transitions.forEach(function (transition) {
            var metadata = {};
            if (realTransitions) {
                realTransitions[transition.name] = {
                    from: transition.from,
                    to: transition.to,
                    guard: transition.guard,
                };
                if (transition.metadata && transition.metadata.length > 0) {
                    transition.metadata.forEach(function (meta) {
                        metadata[meta.name] = meta.value;
                    });
                }
                realTransitions[transition.name].metadata = Object.keys(metadata).length > 0 ? metadata : undefined;
            }
        });
        var realMetadata = {};
        if (metadata && metadata.length > 0) {
            metadata.forEach(function (meta) {
                realMetadata[meta.name] = meta.value;
            });
        }
        return {
            framework: {
                workflows: (_a = {},
                    _a[name] = __assign(__assign({}, data), { metadata: Object.keys(realMetadata).length > 0 ? realMetadata : undefined, audit_trail: { enabled: auditTrail }, supports: supports.map(function (support) { return support.entityName; }), marking_store: markingStore, events_to_dispatch: eventsToDispatch, initial_marking: initialMarking, places: realPlaces, transitions: realTransitions }),
                    _a),
            },
        };
    };
    return WorkflowConfigHelper;
}());
export { WorkflowConfigHelper };
