import React from 'react';
var GoogleAnalytics = React.memo(function (_a) {
    var gaId = _a.gaId;
    return gaId ? (<React.Fragment>
            <script async src={"https://www.googletagmanager.com/gtag/js? \n      id=".concat(gaId)}></script>
            <script id="google-analytics" dangerouslySetInnerHTML={{
            __html: "\n          window.dataLayer = window.dataLayer || [];\n          function gtag(){dataLayer.push(arguments);}\n          gtag('js', new Date());\n\n          gtag('config', '".concat(gaId, "');\n        "),
        }}></script>
        </React.Fragment>) : undefined;
});
export default GoogleAnalytics;
