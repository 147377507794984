import axios from 'axios';
export var getMe = function () {
    return axios.get("/api/users/me");
};
export var deleteMe = function (password) {
    return axios.delete("/api/users/me");
};
export var updateMe = function (data) {
    return axios.put("/api/users/me", data);
};
